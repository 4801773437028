import KakaoMap from './KakaoMap';

const MapPage = () => {
  return (
    <div className="ImageBox">
      <KakaoMap />
    </div>
  );
};

export default MapPage;
