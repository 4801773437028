import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import Image1 from '../../assets/image/room/img_room_1.jpeg';
import Image2 from '../../assets/image/room/img_room_2.jpeg';
import Image3 from '../../assets/image/room/img_room_3.jpeg';
import ImageMainMeant from '../../assets/image/main/image_main_meant.png';

import 'swiper/css';
import '../../assets/scss/components/carousel.scss';
import '../../assets/scss/components/main.scss';

interface itemsProps {
  item: string;
  name: string;
}

const items: itemsProps[] = [
  {
    item: Image1,
    name: '이미지01',
  },
  {
    item: Image2,
    name: '이미지02',
  },
  {
    item: Image3,
    name: '이미지03',
  },
];

const IntroducePage = () => (
  <div>
    <div className="ImageBox">
      <img
        className="title_image"
        alt="Main"
        src={ImageMainMeant}
        style={{ width: '80%' }}
      />
    </div>
    <div>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        rewind={true}
        slidesPerView={1}
        centeredSlides={true}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <img className="ImageBox" src={item.item} alt={item.name} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </div>
);

export default IntroducePage;
