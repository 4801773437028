import { Map, MapMarker } from 'react-kakao-maps-sdk';

const KaKaoMap = () => {
  return (
    <>
      <Map
        style={{ width: '100%', height: '400px' }}
        center={{ lat: 35.5703398, lng: 129.3484281 }}
        level={3}
        zoomable={false}
      >
        <MapMarker position={{ lat: 35.5703398, lng: 129.3484281 }} />
      </Map>
    </>
  );
};

export default KaKaoMap;
