import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import Image1 from '../../assets/image/main/img_main_1.jpeg';
import Image2 from '../../assets/image/main/img_main_2.jpeg';
import Image3 from '../../assets/image/main/img_main_3.jpeg';
import Image4 from '../../assets/image/main/img_main_4.jpeg';
import ImageHome from '../../assets/image/main/img_main_.jpg';
import IntroduceTitle from '../../assets/image/main/introduce_title.png';
import IntroduceContext from '../../assets/image/main/introduce_context.png';
import PhoneCallTitle from '../../assets/image/main/phone_call_title.png';
import PhoneCallButton from '../../assets/image/main/phone_call.png';
import MapTitle from '../../assets/image/main/map_title.png';
import 'swiper/css';
import '../../assets/scss/components/carousel.scss';
import '../../assets/scss/components/main.scss';
import KakaoMap from '../map/KakaoMap';

interface itemsProps {
  item: string;
  name: string;
}

const items: itemsProps[] = [
  {
    item: Image1,
    name: '이미지01',
  },
  {
    item: Image2,
    name: '이미지02',
  },
  {
    item: Image3,
    name: '이미지03',
  },
  {
    item: Image4,
    name: '이미지04',
  },
];

const buttonStyle = {
  background: 'transparent', // 배경을 투명하게 설정
  border: 'none', // 테두리 제거
  cursor: 'pointer', // 마우스 커서 스타일 변경 (선택사항)
};

function phoneCall() {
  location.href = 'tel:01096040242';
}

const MainPage = () => (
  <>
    <div className="ImageBox">
      <img
        className="home_image"
        alt="Home"
        src={ImageHome}
        style={{ width: '100%', paddingBottom: '3%' }}
      />
    </div>
    <div>
      <img
        alt="Main"
        src={IntroduceTitle}
        style={{ width: '10%', marginBottom: '3%', marginLeft: '3%' }}
      />
    </div>
    <div className="ImageBox">
      <img
        className="title_image"
        alt="Main"
        src={IntroduceContext}
        style={{ width: '80%' }}
      />
    </div>
    <div className="container">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        rewind={true}
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <img src={item.item} alt={item.name} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    <div>
      <img alt="Main" src={MapTitle} style={{ width: '15%', margin: '3%' }} />
    </div>
    <div className="ImageBox">
      <KakaoMap />
    </div>
    <div>
      <img
        alt="Main"
        src={PhoneCallTitle}
        style={{ width: '12%', marginBottom: '3%', marginLeft: '3%' }}
      />
    </div>
    <div className="ImageBox">
      <button onClick={phoneCall} style={buttonStyle}>
        <img alt="Main" src={PhoneCallButton} style={{ width: '95%' }} />
      </button>
    </div>
  </>
);

export default MainPage;
