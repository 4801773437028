import '../../assets/scss/components/header.scss';
import HeaderTitle from '../../assets/image/header/header_title.png';

const Header = () => {
  return (
    <>
      <header>
        <div className="inner">
          <h1>
            <a href="/">
              <img src={HeaderTitle} width="80%" />
            </a>
          </h1>
        </div>
      </header>
    </>
  );
};

export default Header;
