import './App.css';
import Layout from './components/layouts/Layout';
import { Route, Routes } from 'react-router-dom';
import MainPage from './pages/main/MainPage';
import IntroducePage from './pages/introduce/IntroducePage';
import MapPage from './pages/map/MapPage';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/introduce" element={<IntroducePage />} />
        <Route path="/map" element={<MapPage />} />
      </Routes>
    </Layout>
  );
}

export default App;
