import '../../assets/scss/components/footer.scss';

const Footer = () => {
  const thisYear = () => {
    const year = new Date().getFullYear();
    return year;
  };

  return (
    <footer>
      <div className="text-center p-2">
        <text>M. 010-9604-0242</text>
        <br></br>
        <text>T. 052)296-0485</text>
        <br></br>
        <text>주소. 울산 중구 번영로 613 3층</text>
        <p>
          Copyright &copy; 고래인력개발 all rights reserved{' '}
          <text>{thisYear()}</text>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
