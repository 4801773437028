import Header from './Header';
import Footer from './Footer';
import '../../assets/scss/components/all.scss';

const Layout = (props: { children: React.ReactNode }) => {
  return (
    <>
      <div id="wrapper">
        <Header />
        <div id="contentWrapper">
          <main>{props.children}</main>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
